///
/// Forty by Pixelarity
/// pixelarity.com | hello@pixelarity.com
/// License: pixelarity.com/license
///

/* List */

	ol {
		list-style: decimal;
		margin: 0 0 _size(element-margin) 0;
		padding-left: 1.25em;

		li {
			padding-left: 0.25em;
		}
	}

	ul {
		list-style: disc;
		margin: 0 0 _size(element-margin) 0;
		padding-left: 1em;

		li {
			padding-left: 0.5em;
		}

		&.alt {
			list-style: none;
			padding-left: 0;

			li {
				border-top: solid 1px _palette(border);
				padding: 0.5em 0;

				&:first-child {
					border-top: 0;
					padding-top: 0;
				}
			}
		}

		&.icons {
			cursor: default;
			list-style: none;
			padding-left: 0;

			li {
				display: inline-block;
				padding: 0 1em 0 0;

				&:last-child {
					padding-right: 0;
				}
			}

			@include breakpoint(small) {
				li {
					padding: 0 0.75em 0 0;
				}
			}
		}

		&.actions {
			cursor: default;
			list-style: none;
			padding-left: 0;

			li {
				display: inline-block;
				padding: 0 (_size(element-margin) * 0.5) 0 0;
				vertical-align: middle;

				&:last-child {
					padding-right: 0;
				}
			}

			&.small {
				li {
					padding: 0 (_size(element-margin) * 0.5) 0 0;
				}
			}

			&.vertical {
				li {
					display: block;
					padding: (_size(element-margin) * 0.5) 0 0 0;

					&:first-child {
						padding-top: 0;
					}

					> * {
						margin-bottom: 0;
					}
				}

				&.small {
					li {
						&:first-child {
							padding-top: 0;
						}
					}
				}
			}

			&.fit {
				display: table;
				margin-left: (_size(element-margin) * -0.5);
				padding: 0;
				table-layout: fixed;
				width: calc(100% + #{(_size(element-margin) * 0.5)});

				li {
					display: table-cell;
					padding: 0 0 0 (_size(element-margin) * 0.5);

					> * {
						margin-bottom: 0;
					}
				}

				&.small {
					margin-left: (_size(element-margin) * -0.5);
					width: calc(100% + #{(_size(element-margin) * 0.5)});

					li {
						padding: 0 0 0 (_size(element-margin) * 0.5);
					}
				}
			}
		}

		&.pagination {
			cursor: default;
			list-style: none;
			padding-left: 0;

			li {
				display: inline-block;
				padding-left: 0;
				vertical-align: middle;

				> .page {
					@include vendor('transition', (
						'background-color #{_duration(transition)} ease-in-out',
						'color #{_duration(transition)} ease-in-out'
					));
					border-bottom: 0;
					display: inline-block;
					font-size: 0.8em;
					font-weight: _font(weight-bold);
					height: 1.5em;
					line-height: 1.5em;
					margin: 0 0.125em;
					min-width: 1.5em;
					padding: 0 0.5em;
					text-align: center;

					&.active {
						background-color: _palette(fg-bold);
						color: _palette(bg);

						&:hover {
							background-color: _palette(highlight);
							color: _palette(bg) !important;
						}

						&:active {
							background-color: desaturate(darken(_palette(highlight), 15), 5);
						}
					}
				}

				&:first-child {
					padding-right: 0.75em;
				}

				&:last-child {
					padding-left: 0.75em;
				}
			}

			@include breakpoint(xsmall) {
				li {
					&:nth-child(n+2):nth-last-child(n+2) {
						display: none;
					}

					&:first-child {
						padding-right: 0;
					}
				}
			}
		}
	}

	dl {
		margin: 0 0 _size(element-margin) 0;

		dt {
			display: block;
			font-weight: _font(weight-bold);
			margin: 0 0 (_size(element-margin) * 0.5) 0;
		}

		dd {
			margin-left: _size(element-margin);
		}
	}
@import 'libs/vars';
@import 'libs/functions';
@import 'libs/mixins';
@import 'libs/skel';

/*
  Forty by Pixelarity
  pixelarity.com | hello@pixelarity.com
  License: pixelarity.com/license
*/

  @include skel-breakpoints((
    xlarge: '(max-width: 1680px)',
    large: '(max-width: 1280px)',
    medium: '(max-width: 980px)',
    small: '(max-width: 736px)',
    xsmall: '(max-width: 480px)',
    xxsmall: '(max-width: 360px)'
  ));

  @include skel-layout((
    reset: 'full',
    boxModel: 'border',
    grid: ( gutters: 2em ),
    breakpoints: (
      large: (
        grid: ( gutters: 1.5em )
      ),
      small: (
        grid: ( gutters: 1.25em )
      )
    )
  ));

  @mixin inner {
    > .inner {
      @include padding(4em, 0);
      margin: 0 auto;
      max-width: _size(inner);
      width: calc(100% - 6em);

      @include breakpoint(small) {
        @include padding(3em, 0);
        width: calc(100% - 3em);
      }
    }
  }

// Base.

  @import 'base/page';
  @import 'base/typography';

// Component.

  @import 'components/section';
  @import 'components/form';
  @import 'components/box';
  @import 'components/icon';
  @import 'components/image';
  @import 'components/list';
  @import 'components/table';
  @import 'components/button';
  @import 'components/tiles';
  @import 'components/contact-method';
  @import 'components/spotlights';

// Layout.

  @import 'layout/header';
  @import 'layout/banner';
  @import 'layout/main';
  @import 'layout/contact';
  @import 'layout/footer';
  @import 'layout/wrapper';
  @import 'layout/menu';

  // Customisations
  
  figure {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  figcaption {
    font-style: italic;
  }
  .center {
    text-align: center;
    margin: auto;
  }
  header.major {
    width: auto;
  }
  .button-small {
    height: 2em;
    padding: 0.4em;
    line-height: normal;
  }
  @media screen and (max-width: 736px) {
    .image.left, .image.right {
      max-width: 50%;
    }
    #banner img {
      max-width: 70vmin;
    }
  }
  
  iframe {
    max-width: 95%;
  }

  // Timeline
  .timeline {
    position: relative;
    max-width: 600px;
    margin: 0 auto;
  }
  .timeline::before {
    content: '';
    position: absolute;
    width: 10px;
    background-color: #893cfd;
    top: 0;
    bottom: 0;
  }
  .timeline-year {
    display: inline-block;
    background-color: #893cfd;
    margin-left: 10px;
    border-radius: 0px 3px 0px 0px;
    padding: 1em;
  }
  .timeline-year h2 {
    color: #f5f804;
    line-height: 0.5em;
    margin-bottom: 0;
  }
  .timeline-items {
    padding: 1em;
    border: 5px solid #893cfd;
    border-radius: 3px;
    margin-bottom: 2em;
  }
  .timeline-items p {
    margin: 0;
  }
  .timeline-items p + p {
    margin-top: 1em;
  }
  .timeline-items img {
    max-width: 100%;
  }

///
/// Forty by Pixelarity
/// pixelarity.com | hello@pixelarity.com
/// License: pixelarity.com/license
///

/* Spotlights */

	.spotlights {
		border-top: 0 !important;

		& + * {
			border-top: 0 !important;
		}

		> section {
			@include vendor('display', 'flex');
			@include vendor('flex-direction', 'row');
			background-color: desaturate(lighten(_palette(bg-alt), 2), 1);

			> .image {
				background-position: center center;
				background-size: cover;
				border-radius: 0;
				display: block;
				position: relative;
				width: 30%;

				img {
					border-radius: 0;
					display: block;
					width: 100%;
				}

				&:before {
					background: transparentize(_palette(bg), 0.1);
					content: '';
					display: block;
					height: 100%;
					left: 0;
					opacity: 0;
					position: absolute;
					top: 0;
					width: 100%;
				}
			}

			> .content {
				@include vendor('display', 'flex');
				@include vendor('flex-direction', 'column');
				@include vendor('justify-content', 'center');
				@include vendor('align-items', 'center');
				@include padding(2em, 3em);
				width: 70%;

				> .inner {
					margin: 0 auto;
					max-width: 100%;
					width: _size(inner);
				}
			}

			&:nth-child(2n) {
				@include vendor('flex-direction', 'row-reverse');
				background-color: desaturate(lighten(_palette(bg-alt), 4), 2);

				> .content {
					@include vendor('align-items', 'flex-end');
				}
			}
		}

		@include breakpoint(xlarge) {
			> section {
				> .image {
					width: 40%;
				}

				> .content {
					width: 60%;
				}
			}
		}

		@include breakpoint(large) {
			> section {
				> .image {
					width: 45%;
				}

				> .content {
					width: 55%;
				}
			}
		}

		@include breakpoint(medium) {
			> section {
				display: block;

				> .image {
					width: 100%;
				}

				> .content {
					@include padding(4em, 3em);
					width: 100%;
				}
			}
		}

		@include breakpoint(small) {
			> section {
				> .content {
					@include padding(3em, 1.5em);
				}
			}
		}
	}
///
/// Forty by Pixelarity
/// pixelarity.com | hello@pixelarity.com
/// License: pixelarity.com/license
///

/* Wrapper */

	#wrapper {
		@include vendor('transition', (
			'filter #{_duration(menu)} ease',
			'-webkit-filter #{_duration(menu)} ease',
			'opacity 0.375s ease-out'
		));
		padding-top: 3.25em;

		&.is-transitioning {
			opacity: 0;
		}

		> * {
			@include inner;
		}

		@include breakpoint(small) {
			padding-top: 2.75em;
		}
	}
///
/// Forty by Pixelarity
/// pixelarity.com | hello@pixelarity.com
/// License: pixelarity.com/license
///

/* Image */

	.image {
		border: 0;
		display: inline-block;
		position: relative;

		img {
			display: block;
		}

		&.left,
		&.right {
			max-width: 30%;

			img {
				width: 100%;
			}
		}

		&.left {
			float: left;
			margin: 0 1.5em 1.25em 0;
			top: 0.25em;
		}

		&.right {
			float: right;
			margin: 0 0 1.25em 1.5em;
			top: 0.25em;
		}

		&.fit {
			display: block;
			margin: 0 0 _size(element-margin) 0;
			width: 100%;

			img {
				width: 100%;
			}
		}

		&.main {
			display: block;
			margin: (_size(element-margin) * 1.25) 0;
			width: 100%;

			img {
				width: 100%;
			}

			@include breakpoint(small) {
				margin: (_size(element-margin) * 0.75) 0;
			}
		}
	}
	
	figure > img {
		max-width: 100%
	}
